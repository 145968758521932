<template>
  <div class="container">
    <div class="header card">
      <h1>
        <span v-lang="'victims.details_title'"></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <Loader2 v-if="!resultsLoaded" />

    <div class="card" v-else>
      <table>
        <tr>
          <td v-lang="'victims.number'"></td>
          <td>{{ model.number }}</td>
        </tr>
        <tr>
          <td v-lang="'victims.institution'"></td>
          <td>{{ model.institution }}</td>
        </tr>
        <tr>
          <td v-lang="'victims.institution_type'"></td>
          <td>{{ model.institutionType }}</td>
        </tr>
        <tr>
          <td v-lang="'victims.registration_date'"></td>
          <td>{{ $moment(model.registrationDate).format('DD.MM.YYYY.') }}</td>
        </tr>
        <tr>
          <td colspan="2" style="font-weight:bold;" v-lang="'victims.person_data'"></td>
        </tr>
        <tr>
          <td v-lang="'victims.gender'"></td>
          <td>{{ model.gender }}</td>
        </tr>
        <tr>
          <td v-lang="'victims.years'"></td>
          <td>{{ model.years }}</td>
        </tr>
        <tr>
          <td v-lang="'victims.citizenship'"></td>
          <td>{{ model.citizenship}}</td>
        </tr>
        <tr>
          <td v-lang="'victims.victim_type'"></td>
          <td>{{ model.victimType}}</td>
        </tr>
        <tr>
          <td v-lang="'victims.recruitment_country'"></td>
          <td>{{ model.recruitmentCountry}}</td>
        </tr>
        <tr>
          <td v-lang="'victims.destination_country'"></td>
          <td>{{ model.destinationCountry}}</td>
        </tr>
        <tr v-if="model.isMinor">
          <td v-lang="'victims.minor_years'"></td>
          <td>{{ model.minorYearsCode }}</td>
        </tr>
      </table>
      <fieldset class="fieldset">
        <legend>{{ $lang("victims.minor") }}</legend>
        <div class="minor">
          <div class="input-checkbox">
            <label for="isMinor">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.isMinor"
                id="isMinor"
                autocomplete="off"
                disabled
                hidden
              />
              <span v-lang="'victims.minor'"></span>
            </label>
          </div>
          <div class="input-checkbox" v-if="model.isMinor == true">
            <label for="isMinorUnaccompanied">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.isMinorUnaccompanied"
                id="isMinorUnaccompanied"
                autocomplete="off"
                disabled
                hidden
              />
              <span v-lang="'victims.minor_unaccompanied'"></span>
            </label>
          </div>
          <div
            class="input m-0"
            :class="
                errors.first('victim_form.fkMinorYearsCodeId') !== undefined
                  ? 'input-error'
                  : ''
              "
            v-if="model.isMinor == true"
          >
            <label for="name">
              <span v-lang="'victims.minor_years'"></span>

              <span class="required-field-star">&#x2605;</span>
            </label>
            <select
              disabled
              v-model="model.fkMinorYearsCodeId"
              name="fkMinorYearsCodeId"
              id="dropdownCitizenshipCode"
              v-validate="model.isMinor ? 'required|excluded:0' : ''"
            >
              <option value="0" selected v-lang="'victims.placeholder_for_minor_years'"></option>
              <option
                v-for="(obj, key) in codesModel.minorYearCodes"
                :key="key"
                :value="obj.pkCodeId"
              >{{ obj.name }}</option>
            </select>
            <div>
              <span class="error" v-html="errors.first('victim_form.fkMinorYearsCodeId')"></span>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{ $lang("victims.exploitation_mode") }}</legend>
        <div class="fieldset-container">
          <div
            v-for="(list, key) in codesModel.exploitationModeCodes"
            class="input-checkbox"
            :key="key"
          >
            <label
              :for="list.pkCodeId"
              :class="
                    (list.pkCodeId != 1466 &&
                      model.exploitationModes.includes(1466)) ||
                    (list.pkCodeId == 1466 &&
                      (model.exploitationModes.includes(1463) ||
                        model.exploitationModes.includes(1464) ||
                        model.exploitationModes.includes(1465)))
                      ? 'disabled'
                      : ''
                  "
            >
              <input
                class="m-0"
                type="checkbox"
                v-model="model.exploitationModes"
                autocomplete="off"
                :value="list.pkCodeId"
                :id="list.pkCodeId"
                disabled
                hidden
              />
              <span v-html="list.name"></span>
            </label>
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{$lang('victims.help_received')}}</legend>
        <div class="input-checkbox">
          <label for="isHelpReceived">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.isHelpReceived"
              id="isHelpReceived"
              autocomplete="off"
              disabled
              hidden
            />
            <span v-lang="'victims.help_received'"></span>
          </label>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{$lang('victims.рesidence_permit')}}</legend>
        <div class="input-checkbox">
          <label for="residencePermit">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.residencePermit"
              id="residencePermit"
              autocomplete="off"
              disabled
              hidden
            />
            <span v-lang="'victims.рesidence_permit'"></span>
          </label>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{$lang('victims.humanitarian_stay')}}</legend>
        <div class="input-checkbox">
          <label for="isHumanitarianStay">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.isHumanitarianStay"
              id="isHumanitarianStay"
              autocomplete="off"
              disabled
              hidden
            />
            <span v-lang="'victims.humanitarian_stay'"></span>
          </label>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{$lang('victims.reflection_period')}}</legend>
        <div class="input-checkbox">
          <label for="reflectionPeriod">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.reflectionPeriod"
              id="reflectionPeriod"
              autocomplete="off"
              disabled
              hidden
            />
            <span v-lang="'victims.reflection_period'"></span>
          </label>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{$lang('victims.status_pretection')}}</legend>
        <div class="input-checkbox">
          <label for="internationalProtection">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.internationalProtection"
              id="internationalProtection"
              autocomplete="off"
              disabled
              hidden
            />
            <span v-lang="'victims.international_protection'"></span>
          </label>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{$lang('victims.victim_cooperation')}}</legend>
        <div class="input-checkbox">
          <label for="cooperation">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.cooperation"
              id="cooperation"
              autocomplete="off"
              disabled
              hidden
            />
            <span v-lang="'victims.cooperation'"></span>
          </label>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{$lang('victims.victim_testifies_in_court')}}</legend>
        <div class="input-checkbox">
          <label for="testifiesInCourt">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.testifiesInCourt"
              id="testifiesInCourt"
              autocomplete="off"
              disabled
              hidden
            />
            <span v-lang="'victims.testifies_in_court'"></span>
          </label>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend v-lang="'victims.recruiting_mode'"></legend>
        <div class="fieldset-container">
          <div
            v-for="(list, key) in codesModel.recruitingModeCodes"
            class="input-checkbox"
            :key="key"
          >
            <label :for="list.pkCodeId">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.recruitingModes"
                :id="list.pkCodeId"
                :value="list.pkCodeId"
                autocomplete="off"
                disabled
                hidden
              />
              <span v-html="list.name"></span>
            </label>
          </div>
          <div
            class="input m-0 custom-input"
            v-if="
                  !$_.isEmpty(model.recruitingModes) &&
                    model.recruitingModes.includes(1473)
                "
            :class="
                errors.first('victim_form.otherRecruitmentMode') !== undefined
                  ? 'input-error'
                  : ''
              "
          >
            <label for="otherRecruitmentMode">
              <span v-lang="'victims.other_recruitment_mode'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <input
              class="m-0"
              v-model="model.otherRecruitmentMode"
              type="text"
              name="otherRecruitmentMode"
              autocomplete="off"
              disabled
              v-validate="'required'"
            />
            <div>
              <span class="error" v-html="errors.first('victim_form.otherRecruitmentMode')"></span>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend v-lang="'victims.offered_assistance_type'"></legend>
        <div class="fieldset-container">
          <div
            v-for="(list, key) in codesModel.offeredAssistanceKindCodes"
            class="input-checkbox"
            :key="key"
          >
            <label :for="list.pkCodeId">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.offeredAssistanceKinds"
                autocomplete="off"
                disabled
                :value="list.pkCodeId"
                :id="list.pkCodeId"
                hidden
              />
              <span v-html="list.name"></span>
            </label>
          </div>
          <div
            v-if="
                  !$_.isEmpty(model.offeredAssistanceKinds) &&
                    model.offeredAssistanceKinds.includes(1490)
                "
            class="input m-0 custom-input"
            :class="
                errors.first('victim_form.otherTypeOfOfferedAssistance') !== undefined
                  ? 'input-error'
                  : ''
              "
          >
            <label for="otherTypeOfOfferedAssistance">
              <span v-lang="'victims.other_type_of_offered_assistance'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <input
              class="m-0"
              v-model="model.otherTypeOfOfferedAssistance"
              type="text"
              name="otherTypeOfOfferedAssistance"
              autocomplete="off"
              disabled
              v-validate="'required'"
            />
            <div>
              <span class="error" v-html="errors.first('victim_form.otherTypeOfOfferedAssistance')"></span>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset" v-if="model.isMinor == true">
        <legend v-lang="'victims.child_support_kind'"></legend>
        <div class="fieldset-container">
          <div
            v-for="(list, key) in codesModel.childSupportKindCodes"
            class="input-checkbox"
            :key="key"
          >
            <label :for="list.pkCodeId">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.childSupportKinds"
                :value="list.pkCodeId"
                :id="list.pkCodeId"
                autocomplete="off"
                disabled
                hidden
              />
              <span v-html="list.name"></span>
            </label>
          </div>
          <div
            class="input m-0 custom-input"
            v-if="
                  !$_.isEmpty(model.childSupportKinds) &&
                    model.childSupportKinds.indexOf(2813) > -1
                "
            :class="
                errors.first('victim_form.otherChildSupportType') !== undefined
                  ? 'input-error'
                  : ''
              "
          >
            <label for="otherChildSupportType">
              <span v-lang="'victims.other_child_support_type'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <input
              class="m-0"
              v-model="model.otherChildSupportType"
              type="text"
              name="otherChildSupportType"
              autocomplete="off"
              disabled
              v-validate="'required'"
            />
            <div>
              <span class="error" v-html="errors.first('victim_form.otherChildSupportType')"></span>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Loader2 from "@/components/General/Loader2";
import store from "./store";
import moment from "moment";
export default {
  components: {
    Loader2
  },
  data() {
    return {
      resultsLoaded: false,
      model: null,
      codesModel: null
    };
  },
  created() {
    this.getById();
  },
  methods: {
    getById() {
      var $this = this;
      store
        .dispatch("GET_VICTIM_BY_ID", this.$route.params.id)
        .then(response => {
          $this.model = response.data.model;

          this.getCodes();
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getCodes() {
      var $this = this;
      store
        .dispatch("GET_CODES")
        .then(response => {
          this.codesModel = response.data.model;

          $this.resultsLoaded = true;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
tr {
  :first-child {
    width: 25%;
  }
}
td:nth-child(2) {
  font-weight: bold;
}
div.input-checkbox {
  color: black;
  display: -webkit-inline-flex;
  margin: 0px 25px;
  margin-top: 5px;

  label {
    margin-bottom: 10px !important;
  }
}
.fieldset {
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  grid-column: 1/4;
  .fieldset-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
}
</style>